<template>
    <section>
      <div class="w-full bg-white mt-4 p-4">
        <div class="w-full flex justify-between">
          <div>
            <p v-if="$route.params.id" class="text-blue-800 text-lg font-bold">
              Editar Cargo
            </p>
            <p v-else class="text-blue-800 text-lg font-bold">
              Crear Cargo
            </p>
          </div>
          <div v-if="dataCargo.actualizo" class="flex justify-end text-xs text-gray-400">
            <i class="pi pi-refresh text-xs mr-2"></i>
            Ultima actualización por: {{ dataCargo.actualizo.persona.full_name }} el día {{ dayjs(dataCargo.updatedAt).format('YYYY/MM/DD') }} a las {{ dayjs(dataCargo.updatedAt).format('HH:mm:ss') }}
          </div>
        </div>
        <div class="w-full grid gap-2 grid-cols-1 lg:grid-cols-2">
          <div class="w-full py-4">
            <div class="rounded-md border p-4 h-full">
              <div class="w-full my-1">
              <label for="name" class="text-sm text-gray-600">Nombre del cargo</label>
                <InputText class="w-full" type="text" id="name" v-model="dataCargo.name" />
              </div>
              <div class="w-full my-1">
                <label for="salario" class="text-sm text-gray-600">Salario</label>
                <InputNumber class="w-full" id="salario" v-model="dataCargo.salario" mode="decimal" :minFractionDigits="2" />
              </div>
              <div class="w-full my-1">
                <label for="descripcion_cargo" class="text-sm text-gray-600">Descripción del cargo</label>
                <Textarea v-model="dataCargo.descripcion_cargo" class="w-full" type="text" id="descripcion_cargo" rows="5" cols="30" />
              </div>
              <div class="w-full my-1">
                <label for="descripcion_cargo" class="text-sm text-gray-600">Área</label>
                <Dropdown class="w-full border-gray-300 rounded-md" v-model="dataCargo.area_id" :options="listadoAreas.filter(a => a.parent_area_id !== null)" optionLabel="nombre_padre" optionValue="id" :filter="true" placeholder="Seleccione" :showClear="true"></Dropdown>
              </div>
            </div>
          </div>
          <div class="w-full py-4">
            <div class="rounded-md border p-4 h-ful">
              <div class="w-full flex justify-between">
                <p class="font-medium">
                  Documentos requeridos para el cargo
                </p>
                <button @click="displayModalDocumentosAdmin = true" class="text-blue-600 flex items-center">
                  <i class="pi pi-cog mr-2"></i>
                  Administrar Documentos
                </button>
              </div>
              <div style="height: 60vh;" class="w-full mt-2 py-2l overflow-y-auto">
                <DataTable
                  v-model:filters="filtrosDocumentos"
                  dataKey="id"
                  filterDisplay="row"
                  :value="listadoDocumentos"
                  responsiveLayout="scroll">
                    <template #empty>
                        Documentos no encontrados.
                    </template>
                    <Column bodyClass="text-xs" field="nombre" headerClass="hidden" style="min-width: 14rem">
                        <template #body="{data}">
                            {{data.nombre}}
                        </template>
                        <template #filter="{filterModel, filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter" placeholder="Buscar por permiso padre" v-tooltip.top.focus="'Tipo de filtro'"/>
                        </template>
                    </Column>
                    <Column bodyClass="text-xs" field="nombre" headerClass="hidden" style="min-width: 14rem">
                        <template #body="{data}">
                          <div class="w-full flex justify-end">
                            <InputSwitch v-model="data.enviar" />
                          </div>
                        </template>
                    </Column>
                </DataTable>
                <Dialog :closable="false" v-model:visible="displayModalDocumentosAdmin" :style="{width: '30vw'}" :modal="true">
                  <template #header>
                    <div class="w-full flex justify-between items-center">
                      <p class="text-lg font-medium">
                        Administrar documentos
                      </p>
                      <Button @click="displayAgregarDocumento = true" class="p-button-outlined font-medium" icon="pi pi-plus" label="Agregar" />
                    </div>
                  </template>
                  <DataTable
                    :value="listadoDocumentos"
                    editMode="row"
                    dataKey="id"
                    v-model:editingRows="filasDocumentosEditados"
                    @rowEditInit="abirEditorFilaDocumento"
                    @row-edit-save="editarFilaDocumento"
                    @rowEditCancel="cancelarEdicionFilaDocumento"
                    responsiveLayout="scroll">
                      <template #empty>
                          Documentos no encontrados.
                      </template>
                      <Column field="nombre" bodyClass="text-sm" headerClass="hidden bg-white" style="width:20%">
                          <template #editor="slotProps">
                              <InputText v-model="slotProps.data[slotProps.column.props.field]" />
                          </template>
                      </Column>
                      <Column headerClass="hidden bg-white" :rowEditor="true" style="width: 10%; min-width:8rem" bodyStyle="text-align:center"></Column>
                  </DataTable>
                  <template #footer>
                      <Button label="Salir" icon="pi pi-times" @click="displayModalDocumentosAdmin = false" class="p-button-text"/>
                  </template>
                </Dialog>
                <Dialog :closable="false" header="Crear documento" v-model:visible="displayAgregarDocumento" :style="{width: '20vw'}" :modal="true">
                  <InputText class="w-full" type="text" v-model="nombreDocumentoCrear" />
                  <template #footer>
                      <Button label="Cancelar" icon="pi pi-times" @click="crearDocumento(1)" class="p-button-text"/>
                      <Button label="Crear" icon="pi pi-check" @click="crearDocumento(2)" autofocus />
                  </template>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-end gap-2">
          <Button label="Cancelar" @click="$router.push({name: 'pharmasan.recursos-humanos.configuracion.cargos'})" class="p-button-secondary"/>
          <Button v-if="$route.params.id" label="Editar" @click="editarCargo" />
          <Button v-else label="Guardar" @click="crearCargo" />
        </div>
      </div>
    </section>
  </template>
  <script>
    import CargosService from '../../../../../services_back_new/cargos.service'
    import AreasService from '../../../../../services_back_new/areas.service'
    import DocumentosService from '../../../../../services_back_new/documentos.service'
    import Swal from 'sweetalert2'
    import dayjs from 'dayjs'
    import { FilterMatchMode } from 'primevue/api'
    import { ref, onMounted } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    export default {
      name: 'crearEditarCargos',
      setup () {
        // Services
        const _CargosService = ref(new CargosService())
        const _AreasService = ref(new AreasService())
        const _DocumentosService = ref(new DocumentosService())
        const router = useRouter()
        const route = useRoute()
        // References
        const listadoCargos = ref([])
        const listadoAreas = ref([])
        const listadoDocumentos = ref([])
        const displayModalDocumentosAdmin = ref(false)
        const displayAgregarDocumento = ref(false)
        const documentosAsociar = ref([])
        const filasDocumentosEditados = ref([])
        const nombreDocumentoCrear = ref('')
        const filaEditando = ref({})
        const filaOriginalDocumento = ref({})
        const dataCargo = ref({
          name: '',
          descripcion_cargo: '',
          area_id: 0,
          updatedAt: dayjs(),
          salario: ''
        })
        const filtrosDocumentos = ref({
          nombre: { value: null, matchMode: FilterMatchMode.CONTAINS }
        })
        // Methods
        const obtenerCargos = () => {
          _CargosService.value.get().then(({ data }) => {
            for (const i of data) {
              listadoCargos.value.push({
                ...i,
                nombre_area: i.area_id ? listadoAreas.value.find(a => a.id === i.area_id).name : ''
              })
            }
          })
        }
        const obtenerAreas = () => {
          _AreasService.value.get().then(({ data }) => {
            for (const i of data) {
              listadoAreas.value.push(
                {
                  ...i,
                  nombre_padre: i.parent_area_id ? data.find(a => a.id === i.parent_area_id).name + ' - ' + i.name : i.name
                }
              )
            }
            obtenerCargos()
          })
        }
        const encontrarCargo = (id) => {
          _CargosService.value.find({ id }).then(({ data }) => {
            dataCargo.value.name = data.name ? data.name : ''
            dataCargo.value.salario = data.salario ? data.salario : 0
            dataCargo.value.descripcion_cargo = data.descripcion_cargo ? data.descripcion_cargo : ''
            dataCargo.value.area_id = data.area_id ? data.area_id : 0
            dataCargo.value.updatedAt = data.updatedAt ? data.updatedAt : ''
            dataCargo.value.actualizo = data.actualizo
            if (data.documentosRelacionados.length) {
              for (const i of listadoDocumentos.value) {
                console.log('entro por aqui', i)
                if (data.documentosRelacionados.some(a => a.id_documento === i.id)) {
                  i.enviar = true
                }
              }
            }
          })
        }
        const obtenerDocumentos = () => {
          listadoDocumentos.value = []
          _DocumentosService.value.get().then(({ data }) => {
            for (const i of data) {
              listadoDocumentos.value.push({
                ...i,
                enviar: false
              })
            }
          })
        }
        const abirEditorFilaDocumento = (event) => {
          filaOriginalDocumento.value[event.index] = { ...listadoDocumentos.value[event.index] }
        }
        const editarFilaDocumento = (event) => {
          const array = listadoDocumentos.value.filter(a => a.id !== event.data.id)
          if (array.some(a => a.nombre.toUpperCase() === event.data.nombre.toUpperCase())) {
            Swal.fire(
              'Error',
              'El nombre de documento que intentas registrar ya existe, intenta con uno nuevo',
              'error'
            ).then(() => {
              cancelarEdicionFilaDocumento(event)
            })
          } else {
            const data = {
              nombre: event.data.nombre,
              id: event.data.id
            }
            _DocumentosService.value.put(data).then(({ data }) => {
              if (data.error) {
                Swal.fire(
                  'Error',
                  'A ocurrido un error al editar el documento',
                  'error'
                )
              } else {
                Swal.fire(
                  'Guardado',
                  'Documento editado con exito',
                  'success'
                ).then(() => {
                  obtenerDocumentos()
                })
              }
            })
          }
        }
        const cancelarEdicionFilaDocumento = (event) => {
          listadoDocumentos.value[event.index] = filaOriginalDocumento.value[event.index]
        }
        const editarCargo = (tipo) => {
          console.log('listado de documentos----->', listadoDocumentos.value)
          dataCargo.value.documentos = listadoDocumentos.value.filter(a => a.enviar === true)
          dataCargo.value.id = route.params.id
          if (!dataCargo.value.name || !dataCargo.value.salario || !dataCargo.value.area_id) {
            Swal.fire(
              'Error',
              'Debes llenar todos los campos para continuar',
              'error'
            )
          } else {
            if (dataCargo.value.documentos.length) {
              const array = listadoCargos.value.filter(a => parseInt(a.id) !== parseInt(route.params.id))
              console.log('this is array--->', array)
              if (array.some(a => a.name.toUpperCase() === dataCargo.value.name.toUpperCase())) {
                Swal.fire(
                  'Error',
                  'El nombre del cargo que intentas crear ya se encuentra registrado, intenta con uno nuevo',
                  'error'
                )
              } else {
                _CargosService.value.put(dataCargo.value).then(({ data }) => {
                  if (data.error) {
                    Swal.fire(
                      'Error',
                      'A ocurrido un error al editar el cargo',
                      'error'
                    )
                  } else {
                    Swal.fire(
                      'Guardado',
                      'Cargo editado con exito',
                      'success'
                    ).then(() => {
                      obtenerCargos()
                      dataCargo.value = {
                        name: '',
                        descripcion_cargo: '',
                        area_id: 0,
                        updatedAt: dayjs(),
                        salario: ''
                      }
                      router.push({ name: 'pharmasan.recursos-humanos.configuracion.cargos' })
                    })
                  }
                })
              }
            } else {
              Swal.fire(
                'Error',
                'Debes seleccionar al menos un documento para el cargo',
                'error'
              )
            }
          }
        }
        const crearCargo = () => {
          dataCargo.value.documentos = listadoDocumentos.value.filter(a => a.enviar)
          if (!dataCargo.value.name || !dataCargo.value.salario || !dataCargo.value.area_id) {
            Swal.fire(
              'Error',
              'Debes llenar todos los campos para continuar',
              'error'
            )
          } else {
            if (dataCargo.value.documentos.length) {
              if (listadoCargos.value.some(a => a.name.toUpperCase() === dataCargo.value.name.toUpperCase())) {
                Swal.fire(
                  'Error',
                  'El nombre del cargo que intentas crear ya se encuentra registrado, intenta con uno nuevo',
                  'error'
                )
              } else {
                _CargosService.value.post(dataCargo.value).then(({ data }) => {
                  if (data.error) {
                    Swal.fire(
                      'Error',
                      'A ocurrido un error al editar el cargo',
                      'error'
                    )
                  } else {
                    Swal.fire(
                      'Guardado',
                      'Cargo creado con exito',
                      'success'
                    ).then(() => {
                      obtenerCargos()
                      dataCargo.value = {
                        name: '',
                        descripcion_cargo: '',
                        area_id: 0,
                        updatedAt: dayjs(),
                        salario: ''
                      }
                      router.push({ name: 'pharmasan.recursos-humanos.configuracion.cargos' })
                    })
                  }
                })
              }
            } else {
              Swal.fire(
                'Error',
                'Debes seleccionar al menos un documento para el cargo',
                'error'
              )
            }
          }
        }
        const crearDocumento = (tipo) => {
          if (tipo === 1) {
            nombreDocumentoCrear.value = ''
            displayAgregarDocumento.value = false
          } else {
            if (listadoDocumentos.value.some(a => a.nombre.toUpperCase() === nombreDocumentoCrear.value.toUpperCase())) {
              Swal.fire(
                'Error',
                'El nombre de documento que intentas registrar ya existe, intenta con uno nuevo',
                'error'
              )
              nombreDocumentoCrear.value = ''
            } else {
              _DocumentosService.value.post({ nombre: nombreDocumentoCrear.value }).then(({ data }) => {
                if (data.error) {
                  Swal.fire(
                    'Error',
                    'A ocurrido un error al crear el documento',
                    'error'
                  )
                } else {
                  Swal.fire(
                    'Guardado',
                    'Documento creado con exito',
                    'success'
                  ).then(() => {
                    obtenerDocumentos()
                    nombreDocumentoCrear.value = ''
                    displayAgregarDocumento.value = false
                  })
                }
              })
            }
          }
        }
        onMounted(() => {
          obtenerDocumentos()
          obtenerAreas()
          if (route.params.id) {
            encontrarCargo(route.params.id)
          }
        })
        return {
          listadoCargos,
          dataCargo,
          editarCargo,
          listadoAreas,
          crearCargo,
          listadoDocumentos,
          displayModalDocumentosAdmin,
          filasDocumentosEditados,
          editarFilaDocumento,
          cancelarEdicionFilaDocumento,
          filtrosDocumentos,
          documentosAsociar,
          displayAgregarDocumento,
          nombreDocumentoCrear,
          crearDocumento,
          filaEditando,
          abirEditorFilaDocumento,
          dayjs
        }
      }
    }
  </script>
  <style>
    ::-webkit-scrollbar {
      width: 0.3rem;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(189, 189, 189);
      border-radius: 9999px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  </style>
