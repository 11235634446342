import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_EMPLEADOS

export default class DocumentosService {
    get () {
        return http.get(`${baseUrl}/documentos/listar-documentos`, {
            headers: {
                loading: true
            }
        })
    }

    post (params) {
        return http.post(`${baseUrl}/documentos/crear-documento`, params, {
            headers: {
                loading: true
            }
        })
    }

    put (params) {
        return http.put(`${baseUrl}/documentos/editar-documento`, params, {
            headers: {
                loading: true
            }
        })
    }
}
